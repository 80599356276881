import React from 'react'

const Footer = () => {
    return (
        <footer className="text-center">
            <img src="assets/img/powered-by-wavetec-logo.svg" alt="Wavetec brand" height="28" />
        </footer>
    )
}

export default Footer
