import React from "react";

import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";

const Loader = ({ color, loading }) => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;
  return (
    <FadeLoader
      color={color}
      loading={loading}
      css={override}
      height={15}
      margin={5}
    />
  );
};

export default Loader;
