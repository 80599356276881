import React, { useEffect, useContext, useState } from "react";
import "../App.css";
import Footer from "../components/Footer";
import Context from "../context/AppContext";
import ApplicationRoutes from "../routes";
import { ToastContainer } from "react-toastify";
import * as helper from "../helpers/helpers";

import { HttpService } from "../services/HttpService";
import Header from "../components/Header";

const Main = () => {
  const {
  } = useContext(Context);


  const httpService = new HttpService();

  return (
    <div className="App">
      <ApplicationRoutes />
      <ToastContainer />
    </div>
  );
};

export default Main;
