import React, { useState } from "react";
import Context from "./AppContext";
const ContextWrapper = ({ children }) => {
	const [customerSessionData, setCustomerSessionData] = useState(null);
	const [appointmentInfo, setAppointmentInfo] = useState(null);
	const [appointmentInfoResp, setAppointmentInfoResp] = useState(null);

	const clearingGlobalStates = () => {
		setCustomerSessionData(null);
	};

	return (
		<Context.Provider
			value={{
				clearingGlobalStates: clearingGlobalStates,

				customerSessionData: customerSessionData,
				setCustomerSessionData: setCustomerSessionData,

				appointmentInfo: appointmentInfo,
				setAppointmentInfo: setAppointmentInfo,

				appointmentInfoResp: appointmentInfoResp,
				setAppointmentInfoResp: setAppointmentInfoResp,
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default ContextWrapper;
