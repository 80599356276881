import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const defaultPosition = toast.POSITION.TOP_RIGHT;

const { DEFAULT_LANG } = window["appconfig"];

export const translations = (language) => {
  let languages = window["locale"];
  var langs = Object.keys(languages);
  var translations = {};

  for (let i = 0; i < langs.length; i++) {
    if (localStorage.getItem("lang")) {
      translations = languages[localStorage.getItem("lang")];
    } else if (language) {
      translations = languages[language];
    } else {
      translations = languages[DEFAULT_LANG];
    }
  }
  return translations;
};

export const liveSearch = (array, keyToSearch, searchPropertiesArr) => {
  const results = array.filter((object) => {
    let isFound = 0;
    for (let i = 0; i < searchPropertiesArr.length; i++) {
      if (
        searchPropertiesArr[i] &&
        object[searchPropertiesArr[i]] !== undefined &&
        object[searchPropertiesArr[i]]
          .toString()
          .toLowerCase()
          .includes(keyToSearch.toLowerCase())
      ) {
        isFound = 1;
        break;
      }
    }

    if (isFound == 1) {
      return object;
    }
  });
  if (keyToSearch) {
    return results;
  } else {
    return array;
  }
};

export const validation = {
  isEmailAddress: function (str) {
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return pattern.test(str); // returns a boolean
  },
  isNotEmpty: function (str) {
    var pattern = /\S+/;
    return pattern.test(str); // returns a boolean
  },
  isNumber: function (str) {
    var pattern = /^\d+$/;
    return pattern.test(str); // returns a boolean
  },
  isSame: function (str1, str2) {
    return str1 === str2;
  },
};

export const disableBack = () => {
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function (event) {
    window.history.go(1);
  };
};

export const secondsToTimeFormat = (actualtime) => {
  if (actualtime) {
    return new Date(actualtime * 1000).toISOString().substr(11, 8);
  } else {
    return "00:00:00";
  }
};

export const alert = (
  type = "success",
  msg,
  autoClose = 2000,
  className = "primaryColor",
  position = defaultPosition
) => {
  if (type === "success") {
    toast.success(msg, {
      autoClose: autoClose === null ? 2000 : autoClose,
      className: className === null ? "primaryColor" : className,
      position: position,
    });
  } else if (type === "error") {
    toast.error(msg, {
      autoClose: autoClose === null ? 2000 : autoClose,
      className: className === null ? "dangerColor" : className,
      position: position,
    });
  }
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const replaceNullFromObject = (someObj, replaceValue = "") => {
  const replacer = (key, value) =>
    String(value) === "null" || String(value) === "undefined"
      ? replaceValue
      : value;
  //^ because you seem to want to replace (strings) "null" or "undefined" too

  return JSON.parse(JSON.stringify(someObj, replacer));
};

export const onBrowserClose = (callback) => {
  window.addEventListener("beforeunload", function (e) {
    var confirmationMessage = "o/";

    (e || window.event).returnValue = confirmationMessage;
    callback();
    this.localStorage.clear();
    this.sessionStorage.clear();
  });
};
