import "./App.css";
import Main from "./screens/Main";
import ContextWrapper from "./context/ContextWrapper";

const App = () => {
  return (
    <ContextWrapper>
      <Main />
    </ContextWrapper>
  );
}

export default App;
