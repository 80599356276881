import React, { useContext, useEffect } from "react";
import { Route, Routes, HashRouter } from "react-router-dom";
import Loader from "../components/Loader";
import Context from "../context/AppContext";

const Index = React.lazy(() => import("../screens/Index"));
const Portal = React.lazy(() => import("../screens/Portal"));
const Login = React.lazy(() => import("../screens/Login"));
const NotFound = React.lazy(() => import("../screens/NotFound"));
const AuthGuard = React.lazy(() => import("../routes/authGuard"));

const ApplicationRoutes = () => {
	const { isLoggedIn, currentComponent } = useContext(Context);

	return (
		<>
			<React.Suspense
				fallback={
					<center>
						<Loader />
					</center>
				}
			>
				<HashRouter hashType="noslash">
					<Routes>
						<Route path="/login" element={<Login />} />
						<Route path="/portal" element={<Portal />} />
						<Route path="/" element={<Index />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</HashRouter>
			</React.Suspense>
		</>
	);
};

export default ApplicationRoutes;
