import axios from "axios";

var { CUSTOMER_API_URL, ET_SERVICE_URL, BRANCH_ID } = window["appconfig"];

const headers = {

	"Content-Type": "application/json",
};

export class HttpService {
	issueAppointment = (data) => {
		let dataToSend = {
			categoryid: data.sqn,
			branchid: BRANCH_ID,
			branchTitle: "-",
			branchAddress: "-",
			branchPhoneNumber: "-",
			slotid: data.slotId,
			deviceType: 2,
			appointmentDate: data.appointmentDate,
			userId: data.email,
			flag: 1,
			isReschedule: data.isReschedule,
			payloads: [
				{
					fieldName: "EmailId",
					fieldValue: data.email,
				},
				{
					fieldName: "MobileNumber",
					fieldValue: data.phoneNumber,
				},
				{
					fieldName: "First Name",
					fieldValue: data.firstName + " " + data.lastName,
				},
				{
					fieldName: "customer_id",
					fieldValue: data.customerId,
				},
				{
					fieldName: "recordID",
					fieldValue: data.recordId,
				},
				{
					fieldName: "visitPurpose",
					fieldValue: data.visitPurpose,
				},
				{
					fieldName: "serviceType",
					fieldValue: data.serviceType,
				},
				{
					fieldName: "serviceOption",
					fieldValue: data.serviceOption,
				},
				{
					fieldName: "note",
					fieldValue: data.note,
				},
				{
					fieldName: "notifyVia",
					fieldValue: data.notify,
				},
			],
			lang: "en",
			slotTime: data.slotTime,
		};
		let response = axios.post(
			`${ET_SERVICE_URL}IssueAppointmentTicket`,
			dataToSend,
			{
				headers,
			}
		);
		return response;
	};

	reScheduleAppointment = (data) => {
		let dataToSend = {
			prevAppointmentNumber: data.prevAppointmentNumber,
			prevApptCategoryId: data.prevApptCategoryId,
			categoryid: data.sqn,
			branchid: BRANCH_ID,
			branchTitle: "-",
			branchAddress: "-",
			branchPhoneNumber: "-",
			slotid: data.slotId,
			deviceType: 2,
			appointmentDate: data.appointmentDate,
			userId: data.email,
			flag: 1,
			isReschedule: data.isReschedule,
			payloads: [
				{
					fieldName: "EmailId",
					fieldValue: data.email,
				},
				{
					fieldName: "MobileNumber",
					fieldValue: data.phoneNumber,
				},
				{
					fieldName: "First Name",
					fieldValue: data.firstName + " " + data.lastName,
				},
				{
					fieldName: "customer_id",
					fieldValue: data.customerId,
				},
				{
					fieldName: "recordID",
					fieldValue: data.recordId,
				},
				{
					fieldName: "visitPurpose",
					fieldValue: data.visitPurpose,
				},
				{
					fieldName: "serviceType",
					fieldValue: data.serviceType,
				},
				{
					fieldName: "serviceOption",
					fieldValue: data.serviceOption,
				},
				{
					fieldName: "note",
					fieldValue: data.note,
				},
				{
					fieldName: "notifyVia",
					fieldValue: data.notify,
				},
			],
			lang: "en",
			slotTime: data.slotTime,
		};
		let response = axios.post(
			`${ET_SERVICE_URL}RescheduleAppointmentTicket`,
			dataToSend,
			{
				headers,
			}
		);
		return response;
	};

	shareCustomerInfo = (infoObject) => {
		let response = axios.post(
			`${CUSTOMER_API_URL}ShareCustomerinfoEmail`,
			infoObject
		);

		return response;
	};

	getSlots = (data) => {
		let dataToSend =
			data.branchId + "|" + data.dayId + "|" + data.date + "|" + data.sqn;
		let response = axios.get(`${ET_SERVICE_URL}Slots/` + dataToSend, {
			headers,
		});
		return response;
	};

	loginPortal = (formData) => {
		let dataToSend = {
			Email: formData.Email,
			Password: formData.Password,
		};
		let response = axios.post(`${CUSTOMER_API_URL}Login`, dataToSend, {
			headers,
		});
		return response;
	};

	getAppointmentInfo = (email) => {
		let lang = "en";
		let response = axios.post(
			`${ET_SERVICE_URL}GetAppointmentInfo`,
			{ email: email, lang: lang },
			{
				headers,
			}
		);
		return response;
	};

	addCustomer = (formData) => {
		let dataToSend = {
			FirstName: formData.FirstName,
			LastName: formData.LastName,
			DOB: formData.dob,
			Email: formData.Email,
			ContactNo: formData.ContactNo,
			Created_By: formData.createdBy,
			Record_ID: formData.recordID,
		};
		let response = axios.post(`${CUSTOMER_API_URL}AddCustomer`, dataToSend, {
			headers,
		});
		return response;
	};

	updateCustomer = (userObject) => {
		let response = axios.post(`${CUSTOMER_API_URL}UpdateCustomer`, userObject);
		return response;
	};

	cancelAppointment = (data) => {
		let dataToSend =
			data.appointmentNumber +
			"|" +
			data.sqn +
			"|" +
			data.branchId +
			"|" +
			data.email +
			"|" +
			data.languageCode;
		let response = axios.get(
			`${ET_SERVICE_URL}CancelAppointmentTicket/` + dataToSend,
			{
				headers,
			}
		);
		return response;
	};

	searchCustomerByType = (type, value) => {
		let response = axios.get(
			`${CUSTOMER_API_URL}SearchCustomerbyType/${type}|${value}`
		);
		return response;
	};

	getCustomerTransactionHistory = (customer_id) => {
		let response = axios.get(
			`${CUSTOMER_API_URL}GetCustomerHistory/${customer_id}`
		);
		return response;
	};
}
