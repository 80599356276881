import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Context from "../context/AppContext";

const Header = () => {
  const { customerSessionData } = useContext(Context);

  return (
    <header className="text-center mb-5">
      {customerSessionData ? (
        <img
          src="assets/img/logo.png"
          alt="San Diego brand"
          className="img-fluid"
        />
      ) : (
        <Link to={"/"}>
          <img
            src="assets/img/logo.png"
            alt="San Diego brand"
            className="img-fluid"
          />
        </Link>
      )}

      <h5 className="mt-2">County Permit Center</h5>
    </header>
  );
};

export default Header;
